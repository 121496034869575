<template>
  <v-badge
    :model-value="!!priority"
    :content="'Prio. ' + priority"
  >
    <v-card
      color="primary"
      density="compact"
      data-cy="rotationUnit"
      style="pointer-events: none"
    >
      <v-card-text v-if="rotationType">
        {{ rotationType }}
        <br />
      </v-card-text>
      <v-card-text v-else-if="unit">
        {{ unit.short_name }} - {{ unit.long_name }} <br />
        <span
          v-if="unit.minimum_vacancies && unit.maximum_vacancies"
          class="text-caption"
        >
          Min: {{ unit.minimum_vacancies.toLocaleString() }} - Max:
          {{ unit.maximum_vacancies.toLocaleString() }}
        </span>
        <br />
        <schedule-flow-requirement-text
          v-if="unit"
          class="text-caption"
          collection="unit"
          :item="unit.id"
        />
      </v-card-text>
      <v-card-text v-else>Keine Einheit ausgewählt</v-card-text>
    </v-card>
  </v-badge>
</template>
<script>
export default {
  props: {
    unit: {
      type: Object,
      default: null,
    },
    rotationType: {
      type: String,
      default: null,
    },
    priority: {
      type: Number,
      default: null,
    },
  },
}
</script>
