<template>
  <div>
    <NodeResizer
      min-width="300"
      min-height="150"
      color="#E6E6E6"
      @resize="resize"
      @resize-end="resizeEnd"
    />
    <Handle
      id="b"
      type="target"
      :position="Position.Top"
    />
    <v-card
      title="Rotationsblock"
      :color="validate ? '#07133B' : 'red'"
      data-cy="rotationsBlock"
      :width="dimensions.width"
      :height="dimensions.height"
      min-width="300"
      min-height="150"
      density="compact"
      variant="outlined"
      :elevation="dragOver ? 6 : 0"
      @dragover="dragOver = true"
      @dragleave="dragOver = false"
      @drop="dragOver = false"
    >
    </v-card>
    <Handle
      id="a"
      type="source"
      :position="Position.Bottom"
    />
  </div>
</template>
<script setup>
import { Handle, Position } from '@vue-flow/core'
import { NodeResizer } from '@vue-flow/node-resizer'
import '@vue-flow/node-resizer/dist/style.css'
</script>
<script>
export default {
  props: {
    months: {
      type: Number,
      default: 0,
    },
    initialDimensions: {
      type: Object,
      default: () => ({ width: 200, height: 100 }),
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['resize'],
  data() {
    return {
      dragOver: false,
      dimensions: {
        width: 200,
        height: 100,
      },
    }
  },
  mounted() {
    this.dimensions = this.initialDimensions
  },
  methods: {
    resize(event) {
      this.dimensions.width = event.params.width
      this.dimensions.height = event.params.height
    },
    resizeEnd() {
      this.$emit('resize')
    },
  },
}
</script>
<style lang="scss">
.vue-flow__resize-control.handle {
  border: none !important;
  background-color: transparent !important;
  width: 10px;
  height: 10px;
}

.vue-flow__handle {
  width: 15px !important;
  height: 5px !important;
  border-radius: 20% !important;
  background-color: #07133b !important;
  border-color: #07133b;
}

.v-card--variant-outlined {
  border: 1px solid !important;
}

.v-card-item {
  padding: 5px 10px;
}

.v-card-title {
  font-size: 100%;
}
</style>
