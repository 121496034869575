<template>
  <v-tooltip v-if="requirements && requirements.length > 2">
    <template #activator="{ props }">
      <span v-bind="{ ...props, ...$attrs }">
        {{ Object.keys(requirements).length }} Bedingung{{
          Object.keys(requirements).length === 1 ? '' : 'en'
        }}
      </span>
    </template>
    <span
      v-bind="$attrs"
      v-html="requirementText"
    ></span>
  </v-tooltip>
  <span
    v-else
    v-bind="$attrs"
    v-html="requirementText"
  ></span>
</template>
<script>
export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    item: {
      type: [String, Number],
      required: true,
    },
    defaultText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      requirementFields: [
        'id',
        'minimum_users',
        'minimum_months',
        'minimum_percentage',
        'minimum_duration',
        'units.unit_id.id',
        'units.unit_id.short_name',
        'units.unit_id.long_name',
        'rotation_types',
        'specialities.speciality_id.id',
        'specialities.speciality_id.name',
        'facilities.facility_id.id',
        'facilities.facility_id.name',
        'label.id',
        'label.name',
        'group',
      ],
      requirements: [],
    }
  },
  computed: {
    requirementText() {
      const groups = Object.values(this.groupedRequirements)
      const text = this.formatParts(
        groups.map((group) =>
          this.formatParts(
            group.map((requirement) => {
              let text = ''
              if (requirement.minimum_users) {
                text = `${requirement.minimum_users} P. `
              }
              text = text + this.getRequirementTitle(requirement, 0)
              return text
            }),
            ' und '
          )
        ),
        ' oder <br />'
      )
      return text == '' ? this.defaultText : text
    },
    groupedRequirements() {
      return this.requirements.reduce(function (r, a) {
        r[a.group] = r[a.group] || []
        r[a.group].push(a)
        return r
      }, Object.create(null))
    },
  },
  async mounted() {
    this.loadData()
    this.$bus.$on('update:' + this.collection + ':' + this.item, this.loadData)
  },
  methods: {
    async loadData() {
      if (!this.item.startsWith('-new-')) {
        await this.$cms
          .request(
            this.$readItems('schedule_requirements', {
              filter: {
                target: {
                  ['item:' + this.collection]: {
                    id: {
                      _eq: this.item,
                    },
                  },
                },
              },
              fields: this.requirementFields,
            })
          )
          .then((response) => {
            this.requirements = response
          })
      }
    },
  },
}
</script>
