<template>
  <v-badge
    :model-value="!!priority"
    :content="'Prio. ' + priority"
  >
    <v-card
      color="accent"
      density="compact"
      data-cy="externalRotation"
    >
      <v-card-text>
        <span v-if="!facilities.length"> Keine Klinik ausgewählt </span>
        <span
          v-for="facility in sortFacilities"
          v-else
          :key="facility.id"
        >
          AR - {{ facility.name }}<br
        /></span>
        <span
          v-if="minimumVacancies !== null"
          class="text-caption"
        >
          Min: {{ minimumVacancies.toLocaleString() }}
        </span>
        <span
          v-if="minimumVacancies !== null && maximumVacancies !== null"
          class="text-caption"
        >
          -
        </span>
        <span
          v-if="maximumVacancies !== null"
          class="text-caption"
        >
          Max: {{ maximumVacancies.toLocaleString() }}
        </span>
        <br />
        <schedule-flow-requirement-text
          v-if="nodeId && nodeId.length === 36"
          class="text-caption"
          collection="schedule_flow_node"
          :item="nodeId"
        />
      </v-card-text>
    </v-card>
  </v-badge>
</template>
<script>
export default {
  props: {
    nodeId: {
      type: String,
      default: null,
    },
    facilities: {
      type: Array,
      default: () => [],
    },
    priority: {
      type: Number,
      default: null,
    },
    minimumVacancies: {
      type: Number,
      default: null,
    },
    maximumVacancies: {
      type: Number,
      default: null,
    },
  },
  computed: {
    sortFacilities() {
      const sortedFacilities = this.facilities
      sortedFacilities.sort((a, b) => {
        const facilityA = a.name
        const facilityB = b.name
        if (facilityA < facilityB) {
          return -1
        }
        if (facilityA > facilityB) {
          return 1
        }
        return 0
      })
      return sortedFacilities
    },
  },
}
</script>
